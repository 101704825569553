.list_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
    gap: 60px;
}

.item_list {
    width: 22% !important;
    text-align: left;
}

.list_num {
    color: #041e63;
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 76px;
}

.landingPage_outter_container {
    text-align: center;
}

.landing_page_laptop {
    margin: auto;
    margin-top: -6.5%;
    width: 80%;
}

@media screen and (min-width: 792px) and (max-width: 992px) {
    .item_list {
        width: 30% !important;
    }
}

@media screen and (max-width: 792px) {
    .item_list {
        width: 48% !important;
    }
    .list_container {
        gap: 45px 5px !important;
    }
}

@media screen and (max-width: 1200px) {
    .landing_page_laptop {
        width: 90% !important;
    }
}

.intro_about_us {
    margin-top: 5% !important;
    color: #1e5afa;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
}

.text_under_numbers {
    font-size: 24px;
    font-weight: 700;
}
