.contact_container {
    display: flex;
    gap: 70px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3.5%;
    margin-bottom: 3.5%;
}
@media screen and (max-width :992px) {
    .contact_container  {
        flex-direction: column;
    }
}
.contact_logo {
    width: 251px;
    height: 200px;
}

.contat_infos {
    display: flex;
    flex-direction: column;
    gap: 7px
}

.contact_infos_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #2E2E2E;
}

.contact_adress {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #575757;
}

.contact_email {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #575757;
}

.contact_tel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #575757;
}