.Input_container {
    display: flex;
    flex-direction: column;

}

.Input_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #FAFAFA;
    margin: 0;
    margin-bottom: 1%;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.muiInput {

    color: rgb(170, 30, 30);
    border: none;
    border: solid 6px rgb(194, 7, 7);
    background-color: #3929c5 !important;
    border-radius: 10px;
    border: 1px solid rgba(30, 90, 250, 1);
    -webkit-text-fill-color: rgb(2, 2, 2);
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid rgba(30, 90, 250, 1);
    -webkit-text-fill-color: rgb(214, 219, 233);
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    box-shadow: 0 0 0 30px rgba(30, 90, 250, 1) inset !important;
    transition: background-color 5000s ease-in-out 0s;
}


.landingPage_Input {
    border: 1px solid #FAFAFA;
    border-radius: 8px;
    background-color: transparent;
    padding: 0.6em 0.25em;
    color: #ffff;
}

.error {
    border-color: red !important;
    color: red;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid #0000;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(235, 227, 227);
    border: 1 solid #0000 !important;
    background-color: rgba(30, 90, 250, 1) !important;
    border-radius: 10px;
    color: white
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: rgb(233, 217, 217) !important;
}