.title_rdv {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #1e5afa;
    margin-bottom: 3%;
}

.rdv_content {
    background-color: #fafafa;
    padding: 3em 4em;
    border: 1px solid #e7ebee;
    border-radius: 10px;
}

.margin_top_button {
    margin-top: 5%;
}
.content_text {
    font-size: 20px;
    font-weight: 600;
    color : #575757;
}