.CallToActionPage_container {
    background-image: url("../StaticAssets/Call\ to\ action\ page.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.logo_container {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: center;
}

.Rdv_component_container {
    display: flex;
    justify-content: center;
}

.contact_component_container {
    display: flex;
    justify-content: center;
}

.logo {
    width: 155px;
    height: 140px;
}


.calltoaction_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 74px;
    width: 602px;
    height: 79px;
    color: #1e5afa;
}

.title_container {
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 992px) {
    .container_second {
        width: 90% !important;
        margin-top: 15% !important;
    }
}

.container_second {
    width: 75% ;
    margin: auto;
}
