.devFac_container {
    margin-top: 8%;
    margin-bottom: 8%;
}

.devFac_title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 36px;
    color: #7239ea;
    margin: 3% 0;
}

.devFac_line {
    width: 157px;
    height: 12px;
}

.devFac_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.retour_a_accueil_container {
    display: flex;
}

.devFac_p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #181c32;
    display: flex;
    flex-direction: column;
}

.list_item {
    width: 75%;
    list-style: none;
    padding: 0;
}
.list_item li::before {
    content: "\2193";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 8px;
    margin-right: 8px;
}
.devFac_logo {
    width: 278px;
    height: 100px;
}

.devFac_button {
    background: #fafafa;
    border: 2px solid #7239ea;
    box-shadow: 0px 100px 80px rgba(56, 56, 56, 0.1), 0px 64.8148px 46.8519px rgba(56, 56, 56, 0.0759259),
        0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407), 0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #7239ea;
    padding: 1% 3%;
}

.extra_content {
    display: flex;
    gap: 40px;
    align-items: center;
    margin: 4% 0;
}
.dev_content {
    margin: 0;
    color: #7239ea;
    font-weight: 600;
    font-size: 20px;
}

.retour_a_accueil {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ff837b;
    text-shadow: 0px 4px 4px rgba(255, 131, 123, 0.25), 0px 4px 4px rgba(255, 131, 123, 0.25);
    cursor: pointer;
}

.underlineText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    text-decoration-line: underline;
    color: #181c32;
    margin: 0;
}
@media screen and (max-width : 992px) {
    .devFac_content {
        flex-direction: column;
    }
    .extra_content {
        flex-direction: column;
   
    }
}