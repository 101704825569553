.landingPage_Background_container {
    background-color: rgb(251, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing_page_img {
    width: 169px;
    height: 151.73px;
}


.landingPage_img_container {
    display: flex;
    flex-direction: row;
    width: 35%;
    justify-content: center;
}


.container_scondary {
    background-image: url("./../StaticAssets/fullBackGround.jpg");
    width: 100% ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}




.landingPage_general_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}