.form_main_container {
    margin: auto;
    background-image: url("./../StaticAssets/from_background.png");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 10%;
    border-radius: 10px;
}

.first_step {
    text-align: center;
    padding: 2.5em 1em;
    border-radius: 10px;
}

.form_title {
    color: #fff;
    margin: auto;
    width: 75%;
    text-align: center;
    margin-bottom: 1.5em;
    font-size: 40px;
    font-weight: 700;
}

.form_second {
    margin: auto;
    background-image: url("./../StaticAssets/from_background.png");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 10%;
    padding: 2em 1em;
    border-radius: 10px;
    display: none;
}

.container_form_margin {
    width: 45%;
    margin: auto;
}

.button_container {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
}

.two_input_container {
    display: flex;
    justify-content: space-between;
}

.margin_top {
    margin-top: 2%;
}

@media screen and (max-width: 992px) {
    .two_input_container {
        display: grid;
        grid-template-columns: 75%;
        align-items: center;
        gap: 40px;
    }

    .two_input_container {
        justify-content: space-around;

    }

    .container_form_margin {
        width: 100% !important;
    }

    .button_container {
        justify-content: center;
    }

    .margin_top {
        margin-top: 40px;
    }
}

.button_capatcha {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width : 992px) {
    .button_capatcha {
        flex-direction: column !important;
        gap: 45px;
    }
}

.Iframe_formulairePage 
{
    width: 100%; 
    height: 100vh;
}