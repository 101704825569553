.exempleClient_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 75px;
    margin-top: 9% !important;
}

.exempleClient_title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 74px;
    color: #1e5afa;
}

.accelerateur {
    margin-top: 10%;
    width: 75%;
}

.startup_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    color: #000f37;
    margin-bottom: 5%;
}

.accelerateur_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    color: #000f37;
}

.upper_line {
    width: 157px;
    height: 12px;
}
.startup {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.startup_logo_container {
    background: #f6f6f8;
    box-shadow: 0px 4px 4px rgba(30, 90, 250, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8% 0em;
    width: 90%;
    margin: auto;
}

.startup_p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #575757;
}

.startup_logo {
    width: 85%;
}

.accelerateur_logo {
    width: 556px;
    height: 349px;
}

.accelerateur_logo_container {
    background: #f6f6f8;
    box-shadow: 0px 4px 4px rgba(30, 90, 250, 0.1);
    border-radius: 10px;
    width: 630px;
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.accelerateur_p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    color: #575757;
}

.exempleClient_startup {
    display: grid;
    width: 100%;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    margin-top: 4%;
}

.exempleClient_accelerateur {
    display: grid;
    width: 100%;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}
@media screen and (max-width : 992px) {
    .exempleClient_startup {
        grid-template-columns : 95% !important;
        justify-content: center !important;
    }
.Logo_Image {
    order : 2 ;
}
.ContainerStart {
    order: 1;
}

}
.second_container {
    margin-top: 6%;
}
