.landingPage_intro {
    background-image: url("../StaticAssets/background\ bleu.png");
    width: 100%;
    padding-bottom: 8%;
}

.container_inside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.landing_page_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 85px;
    line-height: 100%;
    text-align: center;
    color: #FAFAFA;
    margin-top: -3%;
    margin-bottom: 5%;

}


.landingPage_firstParagraph {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #FAFAFA;
    width: 50%;
    margin-bottom: 4%;
}

.sastec_logo {
    text-align: left;
}

@media screen and (max-width : 1200px) {
    .landing_page_title {
        font-size: 40px !important;
    }

    .landingPage_firstParagraph {
        font-size: 25px !important;
    }

    .landing_page_title {
        width: 75% !important;
    }

    .landingPage_firstParagraph {
        width: 75% !important;
    }
}