.footer_container {
    background: #000f37;
    padding: 1em;
}

.footer_content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #fafafa;
    margin: 0;
    text-align: center;
}
